import React, { useState, Suspense, Fragment, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
// import SplashScreen from "./components/SplashScreen"
import Auth from "./components/Auth";
import AuthGuard from "./components/AuthGuard";
//Website Layouts
import AppLayout from "./layouts";
//Views
import LoginForm from "./views/auth/LoginForm";
import RegisterForm from "./views/auth/RegisterForm";
import ApplicationManagement from "./views/apps/Apps";
import PackagesManagement from "./views/packages";
import CustomersManagement from "./views/customers";
import EmailSettings, { PendingSMS } from "./views/settings";
import AdminProfileSettings from "./views/profile";
//Customers Views
import MyAppsManagement from "./views/customerViews/myApps";
import MarketingManagement from "./views/customerViews/market";
import ViewAppPackages from "./views/customerViews/market/ViewPackages";
import ForgetPassword from "./views/auth/ForgetPassword";
import ProfileSettings from "./views/customerViews/profile";
import PackagePayment from "./views/customerViews/payment";
import ChangePassword from "./views/auth/ChangePassword";
import { ConfirmationEmail } from "./views/auth/confirmationEmail";
import { TranslationsList } from "./views/language/TranslationsList";
import { Upgrade } from "./views/customerViews/market/upgrade";
import { ManageOrganizations } from "./views/manageOrganizations";
import { AdditionalOrganizations } from "./views/customerViews";
import WrappedCustomerCreditPage from "./views/customerViews/payment/customerCreditPage";
import { RedirectToMyApp } from "./views/customerViews/market/redirectToMyApp";
import CustomersTracking from "./views/customers/CustomersTracking";
import AppPaymentNaviagte from "./AppPaymentNaviagte";

const adminRoutes = [
  {
    path: "/identity",
    exact: true,
    component: () => <AppPaymentNaviagte />,
  },
  {
    path: "/login",
    exact: true,
    component: () => <LoginForm />,
  },
  {
    path: "/register",
    exact: true,
    component: () => <RegisterForm />,
  },
  {
    path: "/forget",
    exact: true,
    component: () => <ForgetPassword />,
  },
  {
    path: "/reset/:token",
    exact: true,
    component: () => <ChangePassword />,
  },
  {
    path: "/emailconfirmation/:appuserid/:token",
    exact: true,
    component: () => <ConfirmationEmail />,
  },
  {
    path: "/",
    layout: AppLayout,
    guard: Auth,
    childrens: [
      {
        path: "/apps",
        exact: true,
        component: () => <ApplicationManagement />,
      },
      {
        path: "/packages/:appId",
        exact: true,
        component: () => <PackagesManagement />,
      },
      {
        path: "/customers",
        exact: true,
        component: () => <CustomersManagement />,
      },
      {
        path: "/customertracking",
        exact: true,
        component: () => <CustomersTracking />,
      },
      {
        path: "/pendingSMS",
        exact: true,
        component: () => <PendingSMS />,
      },
      {
        path: "/profile",
        exact: true,
        component: () => <AdminProfileSettings />,
      },
      {
        path: "/",
        exact: true,
        component: () => <ApplicationManagement />,
      },
      {
        path: "/languages",
        exact: true,
        component: () => <TranslationsList />,
      },
      {
        path: "/manageOrganizations",
        exact: true,
        component: () => <ManageOrganizations />,
      },
    ],
  },
];

const customerRoutes = [
  {
    path: "/login",
    exact: true,
    component: () => <LoginForm />,
  },
  {
    path: "/register",
    exact: true,
    component: () => <RegisterForm />,
  },

  {
    path: "/forget",
    exact: true,
    component: () => <ForgetPassword />,
  },
  {
    path: "/reset/:token",
    exact: true,
    component: () => <ChangePassword />,
  },
  {
    path: "/emailconfirmation/:appuserid/:token",
    exact: true,
    component: () => <ConfirmationEmail />,
  },
  {
    path: "/",
    layout: AppLayout,
    guard: Auth,
    // bootstrap: AuthGuard,
    childrens: [
      {
        path: "/",
        exact: true,
        component: () => <MyAppsManagement />,
      },
      {
        path: "/myapps",
        exact: true,
        component: () => <MyAppsManagement />,
      },
      {
        path: "/myapps/:appId",
        exact: true,
        component: () => <RedirectToMyApp />,
      },
      {
        path: "/market",
        exact: true,
        component: () => <MarketingManagement />,
      },
      {
        path: "/market/:appId",
        exact: true,
        component: () => <ViewAppPackages />,
      },
      {
        path: "/market/:appId/:packageId",
        exact: true,
        component: () => <ViewAppPackages />,
      },
      {
        path: "/upgrade/:appId/:customerId",
        exact: true,
        component: () => <Upgrade />,
      },
      {
        path: "/checkout/:customerRegisteredAppId/",
        exact: true,
        component: () => <PackagePayment />,
      },
      {
        path: "/profile",
        exact: true,
        component: () => <ProfileSettings />,
      },
      {
        path: "/additionalOrganizations/:model",
        exact: true,
        component: () => <AdditionalOrganizations />,
      },
      {
        path: "/customerCredit",
        exact: true,
        component: () => <WrappedCustomerCreditPage />,
      },

      {
        component: () => <div>Not Found</div>,
      },
    ],
  },
];

const renderRoutes = (routes) => {
  return routes ? (
    <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;
          const Bootstrap = route.bootstrap || Fragment;

          return (
            <Route
              exact={route.exact}
              path={route.path}
              key={i}
              render={(props) => (
                <Layout>
                  <Guard>
                    <Bootstrap>
                      {route.childrens ? (
                        renderRoutes(route.childrens)
                      ) : (
                        <Component {...props} />
                      )}
                    </Bootstrap>
                  </Guard>
                </Layout>
              )}
            />
          );
        })}
      </Switch>
  ) : null;
};

const AppRoutes = () => {
  const [routes, setRoutes] = useState([]);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (user) {
      if (user.roles === "SuperAdmin") {
        setRoutes(adminRoutes);
      } else if (user.roles === "Customer") {
        setRoutes(customerRoutes);
      }
    } else {
      setRoutes(adminRoutes);
    }
  }, [user]);

  return renderRoutes(routes);
};

export default AppRoutes;
