import React, { useEffect, useState } from "react";
import {
  Container,
  Paper,
  Box,
  TextField,
  Typography,
  IconButton,
  Grid,
} from "@material-ui/core";
import { withRouter, Link, useParams } from "react-router-dom";
import styles from "../../assests/styles/views/auth/login";
import LoadingButton from "../../components/ButtonWithLoading";
import { Formik } from "formik";
import { FcLock } from "react-icons/fc";
import checkEmail from "../../helpers/checkEmailFormat";
import { userLogin, setUser, Get } from "../../actions";
import { useDispatch } from "react-redux";
import checkErrors from "../../utils/responseUtils";
import Alert from "@material-ui/lab/Alert";
import { useSnackbar } from "notistack";
import CloseIcon from "@material-ui/icons/Close";
import CheckResponse from "../../utils/responseUtils";
import authUtils from "../../utils/authUtils";
import { SET_USER } from "../../actions/types";
import authService from "../../utils/authUtils";
import {
  AppManagerLogo,
  ImpactSoftLogo,
  LoginImpactSoftLogo,
} from "../../icons";
import { Get_AppsLogos_URL } from "../../constants/apiUrls";
// import { GetAppLogos } from "./getAppLogos";
import { getTranslation } from "../../heplers/translationHelper";
import { URL } from "../../constants";
import { uuid } from "uuidv4";

const LoginForm = ({ history }) => {
  const classes = styles();
  const dispatch = useDispatch();
  const [alert, setAlert] = useState(false);
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [appLogos, setAppLogos] = useState([]);
  useEffect(() => {
    Get(
      {},
      Get_AppsLogos_URL,
      null,
      (resp) => {
        console.log("lsdhfjkghsdjkgfsdg", resp);
        setAppLogos(resp?.data);
      },
      (error) => {}
    );
  }, []);
  useEffect(() => {
    if (
      window.location.href.includes("impact-soft") &&
      window.location.protocol !== "https:"
    ) {
      window.location.replace(
        `https:${window.location.href.substring(
          window.location.protocol.length
        )}`
      );
    }
    if (authService.isAuthenticated() || authService.isRefreshAuthenticated()) {
      history.push("/");
    }
  }, []);
  let unique_id = localStorage.getItem("visitorId");
  if (unique_id == null) {
    unique_id = uuid();
    localStorage.setItem("visitorId", unique_id);
  }
  if(window.location.search.includes("visitorId")){
    localStorage.setItem("visitorId", window.location.search.split("=")[1]);
    unique_id= window.location.search.split("=")[1];
  }
  const redirectURL = authService.getRedirectURL();

  const [visitorId, setVisitorId] = useState(unique_id);
  const [error, setError] = useState("");
  useEffect(() => {
    if(window.location.search.includes("error")){
      setError( getTranslation(
        "You have already an account. Please login here",
        "Vous avez déjà un compte. Merci de vous connecter ici.",
        "Sie haben bereits ein Konto. Bitte hier einloggen."
      ))
    }
    // fetch( URL+"UserActivity/SystemPageLog", {
    //   method: "POST",
    //   cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    //   credentials: "same-origin",

    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   redirect: "follow",
    //   referrerPolicy: "no-referrer",
    //   body: JSON.stringify({
    //     visitorID: visitorId,
    //     status: redirectURL == null ? "direct" : redirectURL,
    //   }),
    // });
  },[]);

  return (
    <>
      <Container maxWidth="sm">
        <Paper elevation={3} className={classes.root}>
          <div className={classes.paper}>
            {/* {params.appId===1?} */}
            <LoginImpactSoftLogo />
            <br />
            <br />
            {error ? <Alert severity="error">{error}</Alert> : null}
            <br />
            <br />
            {/* <FcLock className={classes.avatar} /> */}
            <Typography className={classes.form} component="h1" variant="h4">
              {getTranslation("Sign in", "S'identifier", "Anmelden")}
            </Typography>
            {alert ? (
              <Box my={1} width="100%">
                <Alert
                  severity="error"
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => {
                        setAlert(false);
                      }}
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  Please Check your Email or Password
                </Alert>
              </Box>
            ) : null}
            <Formik
              initialValues={{ email: "", password: "", role: "" }}
              validate={(values) => {
                const errors = {};
                if (!values.email) {
                  errors.email = "Required";
                } else if (checkEmail(values.email)) {
                  errors.email = "Invalid email address";
                }

                return errors;
              }}
              onSubmit={(values, actions) => {
                actions.setSubmitting(true);
                dispatch(userLogin(values)).then((res) => {
                  const errors = CheckResponse(res);
                  if (!errors) {
                    authUtils.setSession(res.data.accessToken);
                    authUtils.setUserRefreshToken(
                      res.data.refreshToken,
                      res.data.refreshTokenExpire
                    );
                    localStorage.setItem("lang", "fr");

                    dispatch({ type: SET_USER, payload: res.data.user });
                    // onLoginSuccessfully({ accessToken: res.data.accessToken, refreshToken: res.data.refreshToken, expiryDate: res.data.accessTokenExpire, refreshExpiryDate: res.data.refreshTokenExpire })

                    if (res.data.user.roles === "SuperAdmin") {
                      if (authService.getRedirectURL()) {
                        const redirectURL = authService.getRedirectURL();
                        authService.removeRedirectURL();

                        history.push(redirectURL);
                      } else {
                        history.push("/apps");
                      }
                    } else if (res.data.user.roles === "Customer") {
                      if (authService.getRedirectURL()) {
                        const redirectURL = authService.getRedirectURL();
                        authService.removeRedirectURL();

                        history.push(redirectURL);
                      } else {
                        history.push("/myapps");
                      }
                    }
                    // enqueueSnackbar("Login Successfully", { variant: "success" });

                    actions.setSubmitting(false);
                  } else {
                    actions.setSubmitting(false);
                    enqueueSnackbar("Please check your Email or Password", {
                      variant: "error",
                    });
                  }
                });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
              }) => (
                <form onSubmit={handleSubmit} className={classes.form}>
                  <TextField
                    fullWidth
                    id="email"
                    label={getTranslation("Email", "E-mail", "Email")}
                    variant="outlined"
                    size="small"
                    margin="normal"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                  <TextField
                    fullWidth
                    id="password"
                    name="password"
                    label={getTranslation(
                      "Password",
                      "Mot de passe",
                      "Password"
                    )}
                    type="password"
                    variant="outlined"
                    margin="normal"
                    size="small"
                    value={values.password}
                    onChange={handleChange}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                  />
                  <LoadingButton
                    color="primary"
                    fullWidth={true}
                    label={getTranslation(
                      "Sign In",
                      "S'identifier",
                      "Anmelden"
                    )}
                    loading={isSubmitting}
                    type="submit"
                    variant="contained"
                    className={classes.submit}
                  />
                </form>
              )}
            </Formik>
            <Box
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Link to="/forget" variant="h6" className={classes.link}>
                <Typography>
                  {getTranslation(
                    "Forget Password",
                    "Forget Password",
                    "Forget Password"
                  )}{" "}
                  ?
                </Typography>
              </Link>

              <Link to="/register" variant="h6" className={classes.link}>
                <Typography>
                  {getTranslation(
                    "Don't have a account? Sign up",
                    "Don't have a account? Sign up",
                    "Don't have a account? Sign up"
                  )}
                </Typography>
              </Link>
            </Box>
          </div>
        </Paper>
        {/* <br />
        <br />
        <GetAppLogos appLogos={appLogos} /> */}
      </Container>
    </>
  );
};

export default withRouter(LoginForm);
